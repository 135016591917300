import React, {useState} from 'react'
import {
  FormDataModel,
  FormFieldModel,
  FormModel,
  initialFormData,
  useFormStore,
} from '../core/formStore'
import {
  formatDateDMYHI,
  formatNumber,
  formatPhoneNumber,
  timeDifference,
} from '../../../../_helpers/_helpers'
import Tippy from '@tippyjs/react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import FormRender from './FormRender'
import {Formik} from 'formik'

type Props = {
  formData: FormDataModel[]
  form: FormModel
  onPageChange?: (page: number) => void
}

type RenderProps = {
  data: FormDataModel
  fields: FormFieldModel[]
  onClick?: (data: FormDataModel) => void
}
const RenderRowValue: React.FC<RenderProps> = ({data, fields, onClick}) => {
  return (
    <>
      <td key={data.id} className='sticky-column bg-white '>
        <div key={data.id} className='d-flex flex-between w-100 align-items-center'>
          {/* first char */}

          <button
            type='button'
            onClick={() => onClick && onClick(data)}
            className='btn btn-sm p-1 flex-grow-1 text-start'
          >
            <div className={clsx('symbol symbol-25px me-2 p-2 me-2')}>
              <span className='symbol-label'>
                <span className='symbol-label bg-dark fs-7 fw-bold text-light'>
                  {data.patron?.name?.charAt(0)}
                </span>
              </span>
            </div>

            <span className='text-nowrap pe-2'>{data.patron?.name}</span>
          </button>

          {/* <div className='d-flex justify-content-end '>
            <Tippy content={`delete ${data.patron?.name}`}>
              <button type='button' className='btn btn-sm btn-icon p-1'>
                <i className='fa-solid text-secondary text-hover-danger fa-trash'></i>
              </button>
            </Tippy>
          </div> */}
        </div>
      </td>

      <td key={data.id}>
        <Tippy content={formatDateDMYHI(data.dateCreated!)}>
          <span>{timeDifference(data.dateCreated!)}</span>
        </Tippy>
      </td>

      {fields.map((field) => (
        <>
          {/* phone */}
          {field.type === 'phone' && (
            <td className='fw-normal' key={field.id}>
              {formatPhoneNumber(data.data[field.id])}
            </td>
          )}

          {/* date */}
          {field.type === 'date' && (
            <td className='fw-normal' key={field.id}>
              {data.data[field.id] ? formatDateDMYHI(data.data[field.id]) : '-'}
            </td>
          )}
          {/* number */}
          {field.type === 'number' && (
            <td className='fw-normal' key={field.id}>
              {formatNumber(data.data[field.id], 2) || '-'}
            </td>
          )}

          {/* text */}
          {(field.type === 'text' ||
            field.type === 'name' ||
            field.type === 'select' ||
            field.type === 'radio' ||
            field.type === 'checkbox' ||
            field.type === 'email' ||
            field.type === 'multiline') && <td key={field.id}>{data.data[field.id] || '-'}</td>}
        </>
      ))}
    </>
  )
}

const FormDataTable: React.FC<Props> = ({formData, form, onPageChange}) => {
  const {formDataPagination} = useFormStore()
  const [showModal, setShowModal] = useState(false)
  const [selectedData, setSelectedData] = useState<FormDataModel | null>(null)
  const handlePageChange = (page: number) => {
    if (onPageChange) {
      onPageChange(page)
    }
  }
  return (
    <>
      <div className='table-responsive'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bolderfs-6 text-gray-800 border-bottom border-gray-200'>
              <th className='min-w-350px fw-bolder sticky-column bg-white ps-2'>Patron</th>
              <th className='min-w-150px fw-bolder'>Date Submitted</th>
              {form.fields
                .filter((f) => f.type !== 'separator' && f.type !== 'readonly')

                .map((field) => (
                  <td key={field.id} className='min-w-250px fw-bolder'>
                    {field.name}
                  </td>
                ))}
            </tr>
          </thead>
          <tbody className='fw-bold text-gray-600'>
            {formData.map((data, index) => (
              <tr key={`${data.id}-$data${index}`}>
                <RenderRowValue
                  onClick={(d) => {
                    setSelectedData(d)
                    setShowModal(true)
                  }}
                  key={data.id}
                  data={data}
                  fields={form.fields}
                />
              </tr>
            ))}
          </tbody>
        </table>
        {/* ... pagination ... */}

        {/* pagination */}
        {formDataPagination.totalResults &&
          formDataPagination.totalResults > formDataPagination.limit && (
            <div className='pagination mt-5'>
              <PaginationControl
                page={formDataPagination.page}
                total={formDataPagination.totalResults || 0}
                limit={formDataPagination.limit}
                changePage={handlePageChange}
              />
            </div>
          )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Form Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex w-100 flex-column'>
            <Formik
              initialValues={selectedData?.data || initialFormData}
              enableReinitialize
              onSubmit={() => {}}
            >
              {({errors, touched}) => (
                <div className='w-100'>
                  <div className='d-flex w-100 mb-10 flex-column'>
                    {/* name */}
                    <div className='d-flex flex-column mb-10'>
                      <label className={clsx('form-label fs-5 fw-bold')}>Name</label>
                      <div className='form-control-plaintext bg-secondary p-5 fw-bold rounded'>
                        {selectedData?.patron?.name || '-'}
                      </div>
                    </div>

                    {/* email */}
                    <div className='d-flex flex-column mb-10'>
                      <label className={clsx('form-label fs-5 fw-bold')}>Email Address</label>
                      <div className='form-control-plaintext bg-secondary p-5 fw-bold rounded'>
                        {selectedData?.patron?.email || '-'}
                      </div>
                    </div>

                    {/* phone */}
                    <div className='d-flex flex-column '>
                      <label className={clsx('form-label fs-5 fw-bold')}>Mobile Number</label>
                      <div className='form-control-plaintext bg-secondary p-5 fw-bold rounded'>
                        {selectedData?.patron?.phone
                          ? formatPhoneNumber(selectedData.patron.phone.toString())
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <FormRender fields={form.fields} mode='view' errors={errors} touched={touched} />
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-outline btn-active-dark'
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FormDataTable
