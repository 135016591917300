import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {
  _deleteForm,
  _deleteFormData,
  _exportFormData,
  _getForm,
  _getFormByHandle,
  _getFormData,
  _getFormPublic,
  _getForms,
  _patchForm,
  _patchFormData,
  _postForm,
  _postFormData,
  _queryFormData,
} from './formsApi'
import {pick} from '../../../../_helpers/_helpers'
import {v4 as uuidv4} from 'uuid'
import {accountInitialValues, AccountModel} from '../../../modules/settings/core/accountsUsersStore'
import {initialPatron, PatronModel} from '../../../modules/patrons/core/patronStore'

export type FormFieldModel = {
  id: string
  name: string
  description?: string
  type:
    | 'text'
    | 'multiline'
    | 'number'
    | 'name'
    | 'email'
    | 'phone'
    | 'date'
    | 'select'
    | 'checkbox'
    | 'radio'
    | 'separator'
    | 'readonly'
  required: boolean
  options: any
  removable?: boolean
}

export type FormModel = {
  id?: string
  account: AccountModel
  name: string
  description?: string
  handle: string
  status: string
  patronTags: string[]
  fields: [FormFieldModel]
  allowAnonymous: boolean
  allowMultiple: boolean
  dateCreated?: string
  dateUpdated?: string

  stats: {
    responses: number
    views: number
  }
}

export type FormDataModel = {
  id?: string
  form?: string
  patron?: PatronModel
  booking?: string
  bookingTicket?: string
  event?: string
  data: object
  dateCreated?: string
  dateUpdated?: string
}

export type FormsPagination = {
  limit: number
  page: number
  totalPages?: number
  totalResults?: number
  account?: string
}

export type FormDataPagination = {
  limit: number
  page: number
  totalPages?: number
  totalResults?: number
  account?: string
}

export type FormsQuery = {
  account: string
  status: string
  page: number
  limit: number
  sortBy: string
}

export type FormDataQuery = {
  patron?: string
  booking?: string
  bookingTicket?: string
  event?: string
  page: number
  limit: number
  sortBy: string
}

export const initialForm: FormModel = {
  account: {...accountInitialValues},
  name: '',
  handle: '',
  patronTags: [],
  description: '',
  status: 'draft',
  allowAnonymous: false,
  allowMultiple: false,
  fields: [{id: uuidv4(), name: 'field', type: 'text', required: false, options: []}],
  dateCreated: '',
  dateUpdated: '',
  stats: {responses: 0, views: 0},
}

export const initialFormField: FormFieldModel = {
  id: uuidv4(),
  name: '',
  type: 'text',
  required: false,
  removable: true,
  options: [],
}

export const initialFormData: FormDataModel = {
  form: '',
  patron: {...initialPatron},
  booking: '',
  event: '',
  data: {},
}

export const initialFormsQuery: FormsQuery = {
  account: '',
  status: 'draft',
  page: 1,
  limit: 10,
  sortBy: 'dateCreated',
}

export const initialFormDataQuery: FormDataQuery = {
  page: 1,
  limit: 15,
  sortBy: 'dateCreated',
}

export const initialFormDataPagination: FormDataPagination = {
  limit: 15,
  page: 1,
  totalPages: 0,
  totalResults: 0,
}

type FormStore = {
  forms: FormModel[]
  pagination: FormsPagination
  formDataPagination: FormDataPagination
  currentForm: FormModel
  currentPublicForm: FormModel
  currentFormData: FormDataModel
  formData: FormDataModel[]

  postForm: (form: FormModel) => Promise<FormModel> // Updated return type
  patchForm: (formId: string, form: Partial<FormModel>) => Promise<FormModel> // Updated return type
  deleteForm: (clientId: string) => Promise<void>
  getForms: (query: FormsQuery) => Promise<string>
  getForm: (formId: string) => Promise<FormModel>
  getFormPublic: (formId: string) => Promise<FormModel>
  getFormByHandle: (handle: string) => Promise<FormModel>
  unsetCurrentForm: () => void
  unsetPublicForm: () => void

  postFormData: (formId: string, data: FormDataModel) => Promise<string>
  patchFormData: (formId: string, data: Partial<FormDataModel>) => Promise<string>
  deleteFormData: (formId: string, dataId: string) => Promise<void>
  getFormData: (formId: string, dataId: string) => Promise<string>
  queryFormData: (formId: string, query: FormDataQuery) => Promise<FormDataModel[]>
  setCurrentFormData: (data: FormDataModel) => void
  unsetCurrentFormData: () => void
  exportFormData: (formId: string, query: FormDataQuery) => Promise<string>
}

const createStore: StateCreator<FormStore> = (set, get) => ({
  forms: [],
  formData: [],
  pagination: {limit: 10, page: 1},
  currentForm: {...initialForm},
  currentPublicForm: {...initialForm},
  currentFormData: {...initialFormData},
  formDataPagination: {...initialFormDataPagination},

  postForm: async (form: FormModel) => {
    const payload = pick(form, [
      'account',
      'description',
      'patronTags',
      'handle',
      'allowAnonymous',
      'status',
      'name',
      'fields',
    ])
    payload.account = payload.account.id
    const response = await _postForm(payload)

    // set current form
    set({currentForm: response.data})

    return response.data
  },

  /**
   *
   * @param form
   * @returns FormModel
   */
  patchForm: async (formId, form: Partial<FormModel>) => {
    const payload = pick(form, [
      'status',
      'description',
      'handle',
      'patronTags',
      'allowMultiple',
      'allowAnonymous',
      'name',
      'fields',
    ])

    // if there's nothing to update just return the current form
    if (Object.keys(payload).length === 0) return get().currentForm

    // patch form
    const response = await _patchForm(formId, payload)

    // update current form
    set({currentForm: response.data})

    // update forms
    const forms = get().forms.map((f) => (f.id === response.data.id ? response.data : f))
    set({forms})

    // return form
    return response.data
  },

  /**
   * Delete Form
   * @param formId
   */
  deleteForm: async (formId: string) => {
    await _deleteForm(formId)

    // remove from forms
    const forms = get().forms.filter((f) => f.id !== formId)
    set({forms})
  },

  getForms: async (query: FormsQuery) => {
    const response = await _getForms(query)

    // update pagination
    const newPagination: FormsPagination = {
      ...get().pagination,
      account: query.account,
      page: response.data.page,
      limit: response.data.limit,
      totalResults: response.data.totalResults,
      totalPages: response.data.totalPages,
    }

    set({forms: response.data.results, pagination: newPagination})

    return response.data.results
  },

  getForm: async (formId: string) => {
    const response = await _getForm(formId)

    // set current form
    set({currentForm: response.data})

    // return form
    return response.data
  },

  getFormByHandle: async (handle: string) => {
    const response = await _getFormByHandle(handle)

    // set current form
    set({currentForm: response.data})

    // return form
    return response.data
  },

  getFormPublic: async (formId: string) => {
    const response = await _getFormPublic(formId)

    // set public form
    set({currentPublicForm: response.data})

    // return form
    return response.data
  },

  unsetPublicForm: () => {
    set({currentPublicForm: {...initialForm}})
  },

  unsetCurrentForm: () => {
    set({currentForm: {...initialForm}})
  },

  postFormData: async (formId: string, data: FormDataModel) => {
    const payload = pick(data, ['data', 'patron', 'booking', 'bookingTicket', 'event'])
    if (!data.booking) delete payload.booking
    if (!data.event) delete payload.event
    if (!data.patron) {
      delete payload.patron
    } else {
      payload.patron = data.patron.id
    }

    const response = await _postFormData(formId, payload)

    // add to form data
    const formData = [...get().formData, response.data]
    set({formData})

    // return results
    return response.data
  },

  patchFormData: async (formId: string, data: Partial<FormDataModel>) => {
    const payload = pick(data, ['data', 'patron', 'booking', 'bookingTicket', 'event'])

    if (!data.booking) delete payload.booking
    if (!data.event) delete payload.event
    if (!data.patron) {
      delete payload.patron
    } else {
      payload.patron = data.patron.id
    }

    // patch form data
    const response = await _patchFormData(formId, data.id!, payload)

    // update formdata
    const formData = get().formData.map((d) => (d.id === response.data.id ? response.data : d))
    set({formData})

    // return form data
    return response.data
  },

  queryFormData: async (formId: string, query: FormDataQuery) => {
    const payload = pick(query, [
      'patron',
      'booking',
      'bookingTicket',
      'event',
      'page',
      'limit',
      'sortBy',
    ])
    const response = await _queryFormData(formId, payload)

    // set formdata
    set({formData: response.data.results})

    // update pagination
    const formDataPagination: FormDataPagination = {
      ...get().formDataPagination,
      page: query.page,
      limit: query.limit,
      totalResults: response.data.totalResults,
      totalPages: response.data.totalPages,
    }

    // internal set
    set({formDataPagination})

    // return formdata[]
    return response.data.results
  },

  deleteFormData: async (formId: string, dataId: string) => {
    await _deleteFormData(formId, dataId)

    // remove from form data
    const formData = get().formData.filter((d) => d.id !== dataId)
    set({formData})
  },

  getFormData: async (formId: string, dataId: string) => {
    const response = await _getFormData(formId, dataId)

    // set current form data
    set({currentFormData: response.data})

    return response.data
  },

  setCurrentFormData: (data: FormDataModel) => {
    set({currentFormData: data})
  },

  unsetCurrentFormData: () => {
    set({currentFormData: {...initialFormData}})
  },

  exportFormData: async (formId: string, query: FormDataQuery) => {
    const payload = pick(query, ['patron', 'booking', 'event'])
    const response = await _exportFormData(formId, payload)

    // return response
    return response.data
  },
})

export const formStore = create(devtools(createStore))
export const useFormStore = formStore
