/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useEventsStore} from '../core/eventsStore'
import {KTIcon} from '../../../../_metronic/helpers'
import {formatMoney, formatNumber, pluralise} from '../../../../_helpers/_helpers'
import {useAccountsUsersStore} from '../../settings/core/accountsUsersStore'
import clsx from 'clsx'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  height?: number
  showPCT?: boolean
}

interface ActivityRingProps {
  percentage: number
  radius: number
  strokeWidth: number
  color: string
}

const ActivityRing: FC<ActivityRingProps> = ({percentage, radius, strokeWidth, color}) => {
  const normalizedRadius = radius - strokeWidth
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - (percentage / 100) * circumference

  return (
    <svg height={radius * 2} width={radius * 2} style={{transform: 'rotate(-90deg)'}}>
      <circle
        stroke='#f5f8fa'
        fill='transparent'
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        stroke={color}
        fill='transparent'
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={strokeDashoffset}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        style={{transition: 'stroke-dashoffset 0.5s ease-out'}}
      />
    </svg>
  )
}

const AnalyticsTicketsProgressCards: FC<Props> = ({className, showPCT = true, height}) => {
  const [bookedPct, setBookedPct] = useState(0)
  const {currentEvent, tickets, ticketsPagination, getTickets} = useEventsStore()
  const {getCurrentAccount} = useAccountsUsersStore()

  useEffect(() => {
    if (!currentEvent.id) return
    getTickets(currentEvent?.id, {...ticketsPagination, page: 1, limit: 99})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent.id])

  useEffect(() => {
    if (!currentEvent.id) return
    const pct = Math.floor(
      (currentEvent.stats.bookingsTicketsCount / currentEvent.stats.ticketsTotalQty) * 100
    )
    setBookedPct(pct)
  }, [currentEvent, tickets])

  return (
    <>
      {currentEvent.stats.bookingsCount > 0 ? (
        <div className={`card border-0 bg-transparent shadow-none card-flush ${className}`}>
          <div className='card-header my-5 p-0 d-flex flex-wrap align-items-start'>
            <div className='card-title d-flex'>
              {showPCT && (
                <div className='d-flex align-items-center me-3 pe-3 border-end'>
                  <div className='position-relative' style={{width: 48, height: 48}}>
                    <ActivityRing
                      percentage={bookedPct}
                      radius={24}
                      strokeWidth={4}
                      color='#3E97FF'
                    />
                    <span className='position-absolute top-50 start-50 translate-middle fs-3 fw-bolder'>
                      {bookedPct}%
                    </span>
                  </div>
                </div>
              )}
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <span className='fs-1 fw-bolder text-dark me-2 lh-1 ls-n2'>
                    {currentEvent.sellTickets
                      ? formatMoney(
                          currentEvent.stats.bookingsValue,
                          getCurrentAccount()?.currency,
                          0,
                          true
                        ) + ' in sales'
                      : formatNumber(currentEvent.stats.bookingsTicketsCount, 0, true) +
                        pluralise(currentEvent.stats.bookingsTicketsCount, ' Ticket') +
                        ` Issued`}
                  </span>
                </div>
                <div className='d-flex'>
                  <span className='text-muted pt-1 fw-semibold fs-6'>{`from ${currentEvent.stats.bookingsTicketsCount} confirmed tickets. `}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='card-body p-0'>
            <div
              className={height ? 'v-scrollable' : ''}
              style={{maxHeight: height ? `${height}px` : 'auto'}}
            >
              {tickets.map((ticket) => {
                const pct = Math.floor((ticket.bookedTickets / ticket.qty) * 100)
                const ringColor = ticket.canBook.canBook
                  ? getCSSVariableValue('--bs-success')
                  : getCSSVariableValue('--bs-danger')

                return (
                  <div key={ticket?.id} className='d-flex align-items-start mb-3 w-100'>
                    <div className='symbol symbol-50px me-5'>
                      <div className='position-relative w-100 h-100'>
                        <ActivityRing
                          percentage={pct}
                          radius={20}
                          strokeWidth={4}
                          color={ringColor}
                        />
                        <span
                          style={{
                            fontSize: '0.8rem',
                          }}
                          className={clsx(
                            'position-absolute top-50 start-50 translate-middle fw-bold'
                          )}
                        >
                          {pct}%
                        </span>
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-grow-1'>
                      <span className='fw-bold'>
                        {ticket.name}
                        {ticket.canBook.canBook === false && (
                          <span className='badge badge-light-danger'>{ticket.canBook.reason}</span>
                        )}
                      </span>
                      <div className='d-flex fw-semibold d-flex w-100 text-muted '>
                        <span className='d-flex me-2'>
                          {formatNumber(ticket.bookedTickets, 0)} tickets issued
                          {currentEvent.sellTickets &&
                            ` for ${formatMoney(
                              ticket.bookedTickets * ticket.price,
                              getCurrentAccount()?.currency,
                              0
                            )}`}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className='card w-100 w-lg-450px min-h-200 bg-transparent mb-5 border-0 shadow-none'>
          <div className='card-body d-flex flex-center flex-column'>
            <KTIcon iconName='alert-circle' className='fs-2hx text-muted' />
            <span className='text-gray-400 fw-bold fs-3'>No bookings yet</span>
            <span className='text-gray-400 fs-6'>Bookings and sales data will appear here.</span>
          </div>
        </div>
      )}
    </>
  )
}

export {AnalyticsTicketsProgressCards}
