import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {
  _patchPatron,
  _deletePatron,
  _createPatronOTP,
  _verifyPatronOTP,
  _getPatronMe,
  CreatePatronType,
  _searchPatron,
  _getPatrons,
  _deletePatronQuery,
  _patchPatronQuery,
  _postPatronQuery,
  _getPatronQueries,
  _searchPatrons,
  _getPatronTags,
  _patchPatronTags,
  _exportPatrons,
  _postPatron,
  _postPatrons,
  _getPatronsFromBookings,
  _registerPatron,
} from './patronApi'
import axios from 'axios'
import {isValidEmail, pick} from '../../../../_helpers/_helpers'
import {BookingsPagination} from '../../bookings/core/bookingsStore'

export type PatronModel = {
  id: string | null
  name: string
  email: string
  phone: number
  isBanned: boolean
  OTP: string | null
  unsubscribedFromEmails?: boolean
  unsubscribedFromSMS?: boolean
  tags?: string[]
  bookingsCount: number
  ticketsCount: number
  ticketsValue: number
}

export type QueryPartModel = {
  field: string
  operator: string
  value: string
  connector: string
}

export type PatronQueryModel = {
  name: string
  filters: QueryPartModel[]
  id?: string
  account?: string
}

export const intitialPatronQuery: PatronQueryModel = {
  name: '',
  filters: [],
}

export const initialPatron: PatronModel = {
  id: null,
  name: '',
  email: '',
  phone: 27,
  isBanned: false,
  OTP: '',
  unsubscribedFromEmails: false,
  unsubscribedFromSMS: false,
  tags: [],
  bookingsCount: 0,
  ticketsCount: 0,
  ticketsValue: 0,
}

export type PatronsPagination = {
  // filters
  account?: string
  tags?: string[]
  page: number
  unsubscribedFromEmails: boolean
  unsubscribedFromSMS: boolean
  limit: number
  totalPages: number
  totalResults: number
  sortBy: string
  patronQuery?: string
}

export const initialPatronPagination: PatronsPagination = {
  tags: [],
  page: 1,
  unsubscribedFromEmails: false,
  unsubscribedFromSMS: false,
  limit: 15,
  totalPages: 1,
  totalResults: 0,
  sortBy: 'name:asc',
}

export type SearchQueryModel = {
  account?: string
  search: string
  limit?: number
  page?: number
}

export const initialSearchQuery: SearchQueryModel = {
  account: '',
  search: '',
  limit: 15,
  page: 1,
}

export type PatchPatronTagsArgs = {
  account?: string
  tags: string[]
  mode: 'list' | 'patronQuery' | 'search'
  list?: string[]
  search?: string
  patronQuery?: string
  operation: 'add' | 'remove'
}

export type ExportPatronArgs = {
  account?: string
  mode: 'list' | 'patronQuery' | 'search' | 'all'
  list?: string[]
  search?: string
  patronQuery?: string
}

type PatronStore = {
  currentPatron: PatronModel
  patrons: PatronModel[]
  isPatronLoggedIn: boolean
  pagination: PatronsPagination
  getPatrons: (query: Partial<PatronsPagination>) => Promise<PatronModel[]>
  exportPatrons: (query: Partial<PatronsPagination>) => Promise<string>
  registerPatron: (patron: CreatePatronType) => Promise<PatronModel> // Updated return type
  postPatron: (patron: Partial<PatronModel>) => Promise<PatronModel> // Updated return type
  postPatrons: (
    account: string,
    patrons: CreatePatronType[],
    onProgress: (progress: number) => void
  ) => Promise<{inserted: number; updated: number; failed: number}> // Updated return type
  patchPatron: (patron: Partial<PatronModel>, account: string | null) => Promise<PatronModel> // Updated return type
  deletePatron: (patronId: string) => Promise<void>
  createPatronOTP: (type: string, data: string) => Promise<string>
  verifyOTP: (OTP: string) => Promise<void>
  unsetPatron: () => void
  updatePatrons: (patrons: PatronModel) => void
  setPatronFromToken: (patronToken: string) => Promise<void>
  loadPatronFromToken: () => Promise<void>
  searchPatron: (search) => Promise<PatronModel>
  searchPatrons: (search: SearchQueryModel) => Promise<PatronModel[]>
  setCurrentPatron: (patron: PatronModel) => void
  getPatronsFromBookings: (bookingsQuery: BookingsPagination) => Promise<PatronModel[]>

  // PATRON QUERIES
  patronQueries: PatronQueryModel[]
  currentPatronQuery: PatronQueryModel
  getPatronQueries: (query: any) => Promise<PatronQueryModel[]>
  postPatronQuery: (query: PatronQueryModel) => Promise<PatronQueryModel>
  patchPatronQuery: (queryId: String, query: PatronQueryModel) => Promise<PatronQueryModel>
  deletePatronQuery: (queryId: string) => Promise<void>
  setCurrentPatronQuery: (id: string) => void
  unsetCurrentPatronQuery: () => void
  savePatronQuery: (query: PatronQueryModel) => Promise<PatronQueryModel>

  // PATRON TAGS
  patronsTags: string[]
  getPatronTags: (account: string | undefined) => Promise<string[]>
  patchPatronTags: (payload: PatchPatronTagsArgs) => Promise<string[]>
}

const createStore: StateCreator<PatronStore> = (set, get) => ({
  currentPatron: {...initialPatron},
  isPatronLoggedIn: false,
  pagination: initialPatronPagination,
  patrons: [],

  setCurrentPatron: (patron: PatronModel) => {
    set((state) => ({
      currentPatron: {
        ...state.currentPatron,
        ...patron,
      },
    }))
  },

  searchPatrons: async (search: SearchQueryModel) => {
    const response = await _searchPatrons(search)

    // get current pagination
    const {pagination} = get()

    set(() => ({
      patrons: response.data.results,
      pagination: {
        ...pagination,
        totalPages: response.data.totalPages,
        totalResults: response.data.totalResults,
        limit: response.data.limit,
        page: response.data.page,
      },
    }))

    return response.data.results
  },

  searchPatron: async (search) => {
    const q = pick(search, ['email', 'phone'])
    const response = await _searchPatron(q)

    return response.data
  },

  getPatrons: async (query: Partial<PatronsPagination>) => {
    const payload = pick(query, [
      'tags',
      'page',
      'limit',
      'sortBy',
      'patronQuery',
      'unsubscribedFromEmails',
      'unsubscribedFromSMS',
      'ticketsCounts',
      'ticketsValue',
      'bookingsCount',
      'isBanned',
      'account',
    ])

    // convert tags to csv
    if (payload.tags && payload.tags.length > 0) {
      payload.tags = payload.tags.join(',')
    }

    const response = await _getPatrons(payload)

    // get current pagination
    const {pagination} = get()
    const newPagination = {
      ...pagination,
      page: payload.page,
      limit: payload.limit,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    }

    set(() => ({
      patrons: response.data.results,
      pagination: {...newPagination},
    }))

    return response.data.results
  },

  getPatronsFromBookings: async (query: BookingsPagination) => {
    const payload = pick(query, ['tickets', 'status', 'tags', 'limit', 'page', 'sortBy'])
    const response = await _getPatronsFromBookings(payload)

    // get current pagination
    const {pagination} = get()

    set(() => ({
      patrons: response.data.results,
      pagination: {
        ...pagination,
        totalPages: response.data.totalPages,
        totalResults: response.data.totalResults,
        limit: response.data.limit,
        page: response.data.page,
      },
    }))

    return response.data.results
  },

  exportPatrons: async (query: Partial<ExportPatronArgs>) => {
    const payload = pick(query, ['account', 'patronQuery', 'search', 'list', 'mode'])

    // convert tags to csv
    if (payload.tags && payload.tags.length > 0) {
      payload.tags = payload.tags.join(',')
    }

    const response = await _exportPatrons(payload)

    return response.data.exportUrl
  },

  registerPatron: async (patron: CreatePatronType) => {
    let savedPatron: any

    const payload = pick(patron, ['name', 'email', 'phone', 'account'])
    savedPatron = await _registerPatron(payload)

    set((state) => ({
      currentPatron: {
        ...state.currentPatron,
        ...savedPatron.data.patron,
      },
    }))

    return get().currentPatron
  },

  postPatron: async (patron: Partial<PatronModel>) => {
    let savedPatron: any
    const payload = pick(patron, [
      'name',
      'phone',
      'email',
      'isBanned',
      'unsubscribedFromEmails',
      'unsubscribedFromSMS',
    ])
    savedPatron = await _postPatron(payload)

    set((state) => ({
      currentPatron: {
        ...state.currentPatron,
        ...savedPatron.data.patron,
      },
    }))

    return get().currentPatron
  },

  postPatrons: async (account: string, patrons: CreatePatronType[], onProgress) => {
    // break up patrons into chunks of 100
    const chunkSize: number = 100
    const chunks: any = []
    for (let i = 0; i < patrons.length; i += chunkSize) {
      chunks.push(patrons.slice(i, i + chunkSize))
    }

    // post patrons in chunks
    let inserted = 0
    let updated = 0
    let failed = 0
    for (const chunk of chunks) {
      try {
        const response = await _postPatrons(account, chunk)
        inserted += response.data.inserted
        updated += response.data.updated
        failed += response.data.failed
      } catch (error: any) {
        failed++
      } finally {
        onProgress(Math.ceil(((inserted + updated + failed) / patrons.length) * 100))
      }
    }

    // return results
    return {inserted, updated, failed}
  },

  patchPatron: async (patron: Partial<PatronModel>, account: string | null) => {
    let savedPatron: any
    const patronId = patron.id
    const payload = pick(patron, [
      'tags',
      'name',
      'phone',
      'email',
      'isBanned',
      'unsubscribedFromEmails',
      'unsubscribedFromSMS',
    ])
    delete patron.id

    if (account) payload.account = account

    if (payload.phone.toString().length < 5) delete payload.phone
    if (!isValidEmail(payload.email)) delete payload.email

    savedPatron = await _patchPatron(patronId, payload)

    // update patrons in store
    const newPatrons = get().patrons.map((p) => {
      if (p.id === patronId) {
        return {...p, ...savedPatron.data}
      } else {
        return p
      }
    })

    set((state) => ({
      patrons: newPatrons,
      currentPatron: {
        ...state.currentPatron,
        ...savedPatron.data,
      },
    }))

    return get().currentPatron
  },

  deletePatron: async (patronId: string) => {
    await _deletePatron(patronId)

    set((state) => ({
      currentPatron: {...initialPatron},
    }))
  },

  createPatronOTP: async (type: string, data: string) => {
    // Implement this function
    const response = await _createPatronOTP(type, data)
    return response.data.OTP
  },

  verifyOTP: async (OTP: string) => {
    // Implement this function
    const response = await _verifyPatronOTP(parseFloat(OTP))

    // save patronToken to local storage
    const {setPatronFromToken} = get()
    setPatronFromToken(response.data.patronToken)
  },

  unsetPatron: () => {
    // Clear the token from localStorage
    localStorage.removeItem('patronToken')

    // Remove the default Authorization header
    delete axios.defaults.headers.common['Authorization']

    // Update the store
    set((state) => ({
      currentPatron: {...initialPatron},
      isPatronLoggedIn: false,
    }))
  },

  updatePatrons: (patron: PatronModel) => {
    // get current patrons
    const {patrons} = get()

    // update patrons in store
    const updatedPatrons = patrons.map((p) => (p.id === patron.id ? patron : p))

    // update store
    set(() => ({patrons: updatedPatrons}))
  },

  setPatronFromToken: async (patronToken: string) => {
    // Remove any existing token and then set the new token in local storage
    localStorage.removeItem('patronToken')
    localStorage.setItem('patronToken', patronToken)

    // Update the default Authorization header for axios
    axios.defaults.headers.common['Authorization'] = `Bearer ${patronToken}`

    // Get patron info
    try {
      const r = await _getPatronMe()
      const newPatron = r.data

      set((state) => ({
        currentPatron: newPatron,
        isPatronLoggedIn: true,
      }))
    } catch (error: any) {
      // Handle error (optional: clear token and/or remove header)
    }
  },

  loadPatronFromToken: async () => {
    const patronToken = localStorage.getItem('patronToken')

    if (patronToken) {
      const {setPatronFromToken} = get()
      await setPatronFromToken(patronToken)
    }
  },

  // PATRON QUERIES
  patronQueries: [],

  currentPatronQuery: intitialPatronQuery,

  getPatronQueries: async (query: any) => {
    const response = await _getPatronQueries(query)

    const formattedResults = response.data.results.map((q) => {
      q.filters = JSON.parse(q.filters)
      return q
    })

    // update store
    set(() => ({patronQueries: formattedResults}))
    return formattedResults
  },

  postPatronQuery: async (query: PatronQueryModel) => {
    const payload = pick(query, ['name', 'filters', 'account'])
    payload.filters = JSON.stringify(payload.filters)
    const response = await _postPatronQuery(payload)

    response.data.filters = JSON.parse(response.data.filters)

    set(() => ({patronQueries: [...get().patronQueries, response.data]}))
    return response.data
  },

  patchPatronQuery: async (queryId, query: PatronQueryModel) => {
    const payload = pick(query, ['name', 'filters'])
    payload.filters = JSON.stringify(payload.filters)

    const response = await _patchPatronQuery(queryId, payload)

    // update existing patrons
    const newPatronQueries: PatronQueryModel[] = get().patronQueries.map((q) => {
      if (q.id === queryId) {
        const parsedFilters = JSON.parse(payload.filters)
        return {...q, ...response.data, filters: parsedFilters}
      } else {
        return q
      }
    })

    // update store
    set(() => ({patronQueries: newPatronQueries}))

    // return results
    return newPatronQueries.find((q) => q.id === queryId)!
  },

  savePatronQuery: async (query: PatronQueryModel) => {
    if (query.id) {
      return get().patchPatronQuery(query.id, query)
    } else {
      return get().postPatronQuery(query)
    }
  },

  deletePatronQuery: async (queryId: string) => {
    await _deletePatronQuery(queryId)

    // remove query from store
    set(() => ({
      patronQueries: get().patronQueries.filter((q) => q.id !== queryId),
    }))
  },

  setCurrentPatronQuery(id) {
    const query = get().patronQueries.find((q) => q.id === id)
    set(() => ({currentPatronQuery: query!}))
  },

  unsetCurrentPatronQuery() {
    set(() => ({currentPatronQuery: intitialPatronQuery}))
  },

  // TAGS
  patronsTags: [],

  getPatronTags: async (account: string | undefined = undefined) => {
    const response = await _getPatronTags(account ? {account} : {})

    set(() => ({patronsTags: response.data}))

    return response.data
  },

  patchPatronTags: async (payload: PatchPatronTagsArgs) => {
    const response = await _patchPatronTags(payload)

    // get current tags
    const {getPatronTags} = get()
    await getPatronTags(payload.account)

    // return patrondata
    return response.data
  },
})

export const patronStore = create(devtools(createStore))
export const usePatronStore = patronStore
