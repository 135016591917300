import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {Modal} from 'react-bootstrap'
import {Field, Formik} from 'formik'
import Tippy from '@tippyjs/react'
import {usePatronStore} from '../../../modules/patrons/core/patronStore'

type Props = {
  className?: string
  onSave: (tags: string[], operation: string) => void
  mode: 'list' | 'patronQuery' | 'search' | undefined
  disabled?: boolean
  options: string[]
}

type FormModel = {
  operation: string
  tags: string
}

const initialFormValues: FormModel = {
  operation: 'add',
  tags: '',
}

const PatronTags: FC<Props> = ({className, onSave, mode, disabled = false, options}) => {
  const [multiSelections, setMultiSelections] = useState<string[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [tagsToChange, setTagsToChange] = useState<string[]>([])
  const {pagination} = usePatronStore()

  // hide the modal when mode is false
  useEffect(() => {
    if (!mode) setShow(false)
  }, [mode])

  // HANDLERS

  const handleTypeheadChange = (selected: any[]) => {
    setMultiSelections(selected as string[])
  }

  const handleChangeAddTags = (selected: any[]) => {
    const tags = selected.map((tag) => (tag.label ? tag.label : tag))
    setTagsToChange(tags)
  }

  const handleSubmit = async (values: FormModel) => {
    let tags: string[] = []
    if (values.operation === 'add') {
      tags = tagsToChange
    } else {
      tags = multiSelections
    }

    onSave && onSave(tags, values.operation)
    setShow(false)
  }

  return (
    <>
      <Tippy content='Add or remove tags'>
        <button
          disabled={!mode}
          type='button'
          className={clsx(`btn btn-icon btn-sm btn-outline btn-active-secondary me-2`)}
          // data-kt-menu-trigger='click'
          // data-kt-menu-placement='bottom-end'
          // data-kt-meny-id='patron-tags-menu'
          onClick={() => setShow(true)}
        >
          <KTIcon
            iconName='color-swatch'
            iconType={mode ? 'solid' : 'outline'}
            className={clsx('fs-3', {
              'text-dark': mode,
            })}
          />
        </button>
      </Tippy>

      {/* DROPDOWN */}
      <Modal show={show} centered onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Tags</Modal.Title>
        </Modal.Header>
        <Formik enableReinitialize initialValues={initialFormValues} onSubmit={handleSubmit}>
          {(form) => (
            <>
              <Modal.Body>
                <div className='d-flex flex-column'>
                  {/* SELECT OPERATION  */}
                  <div className='d-flex justify-content-between align-items-center mb-10'>
                    <label className='form-label fs-5'>1. What do you want to do?</label>
                    <div
                      className='btn-group btn-group-sm btn-group-toggle'
                      role='group'
                      aria-label='Basic checkbox toggle button group'
                    >
                      <Field
                        type='radio'
                        className='btn-check'
                        name='operation'
                        autoComplete='off'
                        checked={form.values.operation === 'add'}
                      />
                      <label
                        onClick={() => form.setFieldValue('operation', 'add')}
                        className='btn btn-outline btn-active-secondary'
                      >
                        Add Tags
                      </label>

                      <Field
                        type='radio'
                        className='btn-check'
                        name='operation'
                        autoComplete='off'
                        checked={form.values.operation === 'remove'}
                      />
                      <label
                        onClick={() => form.setFieldValue('operation', 'remove')}
                        className='btn btn-outline btn-active-secondary '
                      >
                        Remove Tags
                      </label>
                    </div>
                  </div>

                  <div className='d-flex flex-column mb-10'>
                    <label className='form-label fs-5'>
                      2. Type the tags you want to {form.values.operation}
                    </label>
                    {form.values.operation === 'remove' ? (
                      <Typeahead
                        id='patron-tags-menu'
                        multiple
                        onChange={handleTypeheadChange}
                        options={options}
                        selected={multiSelections}
                      />
                    ) : (
                      <>
                        <Typeahead
                          id='patrons-add-tags-menu'
                          multiple
                          onChange={handleChangeAddTags}
                          options={options}
                          selected={tagsToChange}
                          allowNew
                        />
                        <div className='d-flex flex-wrap'>
                          {form.values.tags.split(',').map((tag, index) => (
                            <span
                              key={`${tag}-${index}`}
                              className='badge badge-light badge-sm me-2'
                            >
                              {tag.trim()}
                            </span>
                          ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className='d-flex fs-5 flex-column fw-semibold'>
                    <span className='fw-bold me-1 text-nowrap'>3. Important Note:</span>
                    {mode === 'patronQuery' && (
                      <span className='fw-normal'>
                        {form.values.tags.length === 1
                          ? `This tag will be ${
                              form.values.operation === 'add' ? 'added to' : 'removed from'
                            } all the patrons in the selected circle`
                          : `These tags will be ${
                              form.values.operation === 'add' ? 'added to' : 'removed from'
                            } all the patrons in the selected circle`}
                      </span>
                    )}
                    {mode === 'list' && (
                      <span className='fw-normal'>
                        {form.values.tags.length === 1
                          ? `This tag will be ${
                              form.values.operation === 'add' ? 'added to' : 'removed from'
                            } all selected patrons only`
                          : `These tags will be ${
                              form.values.operation === 'add' ? 'added to' : 'removed from'
                            } all the selected patrons only`}
                      </span>
                    )}
                    {mode === 'search' && (
                      <span className='fw-normal'>
                        {form.values.tags.length === 1
                          ? `This tag will be ${
                              form.values.operation === 'add' ? 'added to' : 'removed from'
                            } all patrons in the search result`
                          : `These tags will be ${
                              form.values.operation === 'add' ? 'added to' : 'removed from'
                            } all ${pagination.totalResults} patrons in the search result`}
                      </span>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className='d-flex align-items-center'>
                  <button
                    className='btn btn-active-danger btn-sm me-2'
                    onClick={() => setShow(false)}
                  >
                    Close
                  </button>
                  <button
                    disabled={tagsToChange.length === 0}
                    onClick={() => form.submitForm()}
                    className='btn btn-sm btn-outline min-w-100px btn-active-dark'
                  >
                    {form.values.operation === 'add' ? 'Add Tags' : 'Remove Tags'}
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export {PatronTags}
