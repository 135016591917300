import {useEffect, useState} from 'react'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {useTransactionStore} from './core/transactionsStore'
import {timeDifference, formatDateDMYHI, formatMoney, pick} from '../../../_helpers/_helpers'
import clsx from 'clsx'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {useAccountsUsersStore} from '../settings/core/accountsUsersStore'
import {KTIcon} from '../../../_metronic/helpers'
import {useStatementStore} from './core/statementsStore'
import {usePayoutsStore} from './core/payoutsStore'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {useAppSettingsStore} from '../settings/core/appSettingsStore'
import {RequestPayoutButton} from './partials/RequestPayoutButton'

const TransactionsPage = () => {
  const {transactions, getTransactions, getSummary} = useTransactionStore()
  const {pending, payouts, getPayouts} = usePayoutsStore()
  const {statements, getStatements, balance} = useStatementStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const {setCurrentPage, setBreadcrumbs} = useAppSettingsStore()

  const [isLoading, setIsLoading] = useState(false)
  const statementStore = useStatementStore()
  const transactionStore = useTransactionStore()
  const payoutsStore = usePayoutsStore()

  const [tPagination, setTPagination] = useState(transactionStore.pagination)
  const [sPagination, setSPagination] = useState(statementStore.pagination)
  const [pPagination, setPPagination] = useState(payoutsStore.pagination)
  const [viewMode, setViewMode] = useState<'statement' | 'payouts'>('statement')

  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return

    // set is loading etc.
    setIsLoading(true)

    // load page data
    Promise.all([
      getTransactions(
        selectedAccountsUsers.account.id,
        pick(sPagination, ['limit', 'page', 'account'])
      ),
      getStatements(
        selectedAccountsUsers.account.id,
        pick(tPagination, ['limit', 'page', 'account'])
      ),
      getPayouts(
        selectedAccountsUsers.account.id,
        pick({...pPagination, sortBy: 'dateCreated:desc'}, ['limit', 'page', 'account'])
      ),
      getSummary(selectedAccountsUsers.account.id),
    ]).finally(() => setIsLoading(false))

    // eslint-disable-next-line
  }, [selectedAccountsUsers.account.id])

  // update pagination when transactions or statements change or payouts
  useEffect(() => {
    setTPagination(transactionStore.pagination)
    setSPagination(statementStore.pagination)
    setPPagination(payoutsStore.pagination)
  }, [transactionStore.pagination, statementStore.pagination, payoutsStore.pagination])

  // Setup Breadcrumbs
  useEffect(() => {
    setBreadcrumbs([])
    setCurrentPage('Account')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions])

  const handleStatementPaginationChange = (page: number) => {
    getStatements(selectedAccountsUsers.account.id, {...sPagination, page}).finally(() => {})
  }

  const handlePayoutsPaginationChange = (page: number) => {
    setIsLoading(true)
    getPayouts(selectedAccountsUsers.account.id, {...pPagination, page}).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <>
      {/* HEADER */}
      <div className='row'>
        <div className='d-flex flex-column'>
          <div className='d-flex justify-content-between mb-10'>
            <div className='glass-pane p-3 border border-solid rounded'>
              <button
                type='button'
                className={clsx('btn btn-sm btn-outline btn-active-dark me-2', {
                  active: viewMode === 'statement',
                })}
                style={{backgroundColor: 'rgba(255,255,255,0.5)'}}
                onClick={() => {
                  setViewMode('statement')
                }}
              >
                Statement
              </button>
              <button
                type='button'
                className={clsx('btn btn-sm btn-outline btn-active-dark me-2', {
                  active: viewMode === 'payouts',
                })}
                style={{backgroundColor: 'rgba(255,255,255,0.5)'}}
                onClick={() => {
                  setViewMode('payouts')
                }}
              >
                Payouts
              </button>
            </div>

            <div className='d-flex align-items-center'>
              <RequestPayoutButton disabled={false} />
              {viewMode === 'statement' ? (
                <div className='d-flex flex-column ms-3 me-0'>
                  <span className='className fs-7 text-muted text-start'>Balance</span>
                  <div className='account-balance fw-bolder fs-1 text-start'>
                    {formatMoney(balance, selectedAccountsUsers.account.currency, 0)}
                  </div>
                </div>
              ) : (
                <div className='d-flex flex-column ms-3 me-0'>
                  <span className='className fs-7 text-muted text-start'>Pending</span>
                  <div className='account-balance fw-bolder fs-1 text-warning text-start'>
                    {formatMoney(pending, selectedAccountsUsers.account.currency, 0)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* TRANSACTIONS */}
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {viewMode === 'statement' ? (
            <div className='d-flex flex-column'>
              {/* STATEMENTS */}
              {isMobileDevice() ? (
                <>
                  {statements.map((statement) => (
                    <div
                      key={statement.id}
                      className='d-flex flex-column p-3 border-bottom border-secondary mb-3'
                    >
                      <div className='d-flex justify-content-between mb-2'>
                        <div className='dates fw-bolder'>
                          {formatDateDMYHI(
                            statement.dateCreated?.toString() || new Date().toString()
                          )}
                        </div>
                        <div className='balance fw-bolder'>
                          <div className='d-flex text-end justify-content-end'>
                            {formatMoney(
                              statement.balance,
                              selectedAccountsUsers.account.currency,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <div className='description d-flex flex-fill'>{statement.description}</div>
                        <div
                          className='text-end d-flex justify-content-end'
                          style={{minWidth: '130px'}}
                        >
                          <div className='d-flex justify-content-end'>
                            {statement.amount > 0 ? (
                              <KTIcon
                                iconName='double-up'
                                className='svg-icon-2x text-success me-1'
                              />
                            ) : (
                              <KTIcon
                                iconName='double-down'
                                className='svg-icon-2x text-danger me-1'
                              />
                            )}
                            {formatMoney(
                              statement.amount,
                              selectedAccountsUsers.account.currency,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {statements.length === 0 ? (
                    <div className='min-h-250px d-flex flex-grow-1 border-1 justify-content-center border-dashed rounded bg-light'>
                      <div className='d-flex justify-content-center align-items-center flex-column'>
                        <div className='d-flex'>
                          <KTIcon
                            className='svg-icon-3x svg-icon-primary mb-5 '
                            iconName='flaticon-safebox-1'
                          />
                          <span className='fs-2x fw-bolder text-dark'>
                            No statement entries yet.{' '}
                          </span>
                        </div>
                        <div className='fs-5 text-muted text-center'>
                          As soon as patrons make bookings, transactions will be recorded here.
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <table className='table table-striped table-hover table-row-bordered table-row-gray-300 gy-7'>
                        <thead>
                          <tr className='fw-bolder fs-6 text-gray-800'>
                            <th className='w-175px'>Date</th>
                            <th className=''>Description</th>
                            <th className='text-end w-115px'>Amount</th>
                            <th className='text-end w-115px'>balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {statements.map((statement) => (
                            <tr key={statement.id}>
                              {/* DATE */}
                              <td>
                                <div className='d-flex align-items-center'>
                                  {formatDateDMYHI(
                                    statement.dateCreated?.toString() || new Date().toString()
                                  )}
                                </div>
                              </td>

                              {/* DESCRIPTION */}
                              <td>{statement.description}</td>

                              {/* AMOUNT */}
                              <td className='text-end'>
                                <div className='d-flex justify-content-end'>
                                  {statement.amount > 0 ? (
                                    <KTIcon
                                      iconName='double-up'
                                      className='svg-icon-2x text-success me-1'
                                    />
                                  ) : (
                                    <KTIcon
                                      iconName='double-down'
                                      className='svg-icon-2x text-danger me-1'
                                    />
                                  )}
                                  {formatMoney(
                                    statement.amount,
                                    selectedAccountsUsers.account.currency,
                                    2
                                  )}
                                </div>
                              </td>

                              {/* BALANCE */}
                              <td className='text-end'>
                                {formatMoney(
                                  statement.balance,
                                  selectedAccountsUsers.account.currency,
                                  2
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {statements.length < sPagination.totalResults && (
                        <div className='my-10'>
                          <PaginationControl
                            page={sPagination.page}
                            total={sPagination.totalResults}
                            limit={sPagination.limit}
                            changePage={handleStatementPaginationChange}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className='d-flex flex-column'>
              {isMobileDevice() ? (
                <>
                  {payouts.map((payout) => (
                    <div key={payout.id} className='card mb-2'>
                      <div className='card-body'>
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex flex-column'>
                            <span className=''>
                              {formatDateDMYHI(payout?.dateCreated?.toString())}
                            </span>
                          </div>
                          <span className=''>
                            {formatMoney(payout.amount, selectedAccountsUsers.account.currency, 2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {/* table  */}
                  <table className='table table-striped table-hover table-row-bordered table-row-gray-300 gy-7'>
                    <thead>
                      <tr className='fw-bolder fs-6 text-gray-800'>
                        <th className='ps-5'>Request Date</th>
                        <th className='ps-5'>Updated Date</th>
                        <th>Notes</th>
                        <th className='text-end'>Status</th>
                        <th className='text-end'>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payouts.map((payout) => (
                        <tr key={payout.id}>
                          <td className='ps-5'>
                            <div className='d-flex align-items-center'>
                              <span
                                className={clsx('badge badge-circle w-10px h-10px me-5', {
                                  'badge-success': payout.status === 'complete',
                                  'badge-warning': payout.status === 'pending',
                                  'badge-info': payout.status === 'holding',
                                  'badge-danger': payout.status === 'cancelled',
                                })}
                              />
                              <div className='d-flex flex-column'>
                                <span className='fw-bolder'>
                                  {timeDifference(payout.dateCreated!)}
                                </span>
                                <span className='fw-normal'>
                                  {formatDateDMYHI(payout?.dateCreated?.toString())}
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className='ps-5'>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex flex-column'>
                                <span className='fw-bolder'>
                                  {timeDifference(payout.dateUpdated!)}
                                </span>
                                <span className='fw-normal'>
                                  {formatDateDMYHI(payout?.dateUpdated?.toString())}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>{payout.notes}</td>
                          <td className='text-end'>
                            <span
                              className={clsx('badge', {
                                'badge-success': payout.status === 'complete',
                                'badge-warning': payout.status === 'pending',
                                'badge-info': payout.status === 'holding',
                                'badge-danger': payout.status === 'cancelled',
                              })}
                            >
                              {payout.status}
                            </span>
                          </td>
                          <td className='text-end'>
                            {formatMoney(payout.amount, selectedAccountsUsers.account.currency, 2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {payouts.length < pPagination.totalResults && (
                    <div className='my-10'>
                      <PaginationControl
                        page={pPagination.page}
                        total={pPagination.totalResults}
                        limit={pPagination.limit}
                        changePage={handlePayoutsPaginationChange}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export {TransactionsPage}
