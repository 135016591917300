import React, {useState, useEffect} from 'react'
import DataTable from 'react-data-table-component'

import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {useAccountsUsersStore} from '../../modules/settings/core/accountsUsersStore'

import {
  ExportPatronArgs,
  initialPatronPagination,
  PatchPatronTagsArgs,
  PatronModel,
  PatronsPagination,
  usePatronStore,
} from '../../modules/patrons/core/patronStore'

import {
  formatMoney,
  formatNumber,
  formatPhoneNumber,
  pluralise,
  trimCharacters,
} from '../../../_helpers/_helpers'

import clsx from 'clsx'
import {KTIcon} from '../../../_metronic/helpers'
import {PatronQueryEditor} from './partials/PatronQueryEditor'
import toast, {Toaster} from 'react-hot-toast'
import QueryPartBadge from './partials/QueryPartBadge'
import {BreadcrumbModel, useAppSettingsStore} from '../../modules/settings/core/appSettingsStore'
import {pick} from 'react-bootstrap-typeahead/types/utils'
import {PatronTags} from './partials/PatronTags'
import {Modal} from 'react-bootstrap'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import PatronEditor from './partials/PatronEditor'
import PatronImporter from './partials/PatronImporter'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import Tippy from '@tippyjs/react'
import Swal from 'sweetalert2'
import {useEventsStore} from '../../modules/events/core/eventsStore'

const AudiencePage: React.FC = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const [exporting, setExporting] = useState(false)
  const [selectedRows, setSelectedRows] = useState<PatronModel[]>([])
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()
  const {
    patrons,
    pagination,
    currentPatronQuery,
    setCurrentPatronQuery,
    unsetCurrentPatronQuery,
    patronQueries,
    searchPatrons,
    getPatrons,
    updatePatrons,
    patchPatronTags,
    getPatronTags,
    patronsTags,
    exportPatrons,
  } = usePatronStore()
  const [isPatronQueryApplied, setIsPatronQueryApplied] = useState(false)
  const [isSearchApplied, setIsSearchApplied] = useState(false)
  const [mode, setMode] = useState<'list' | 'patronQuery' | 'search' | undefined>(undefined)
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [showPatronQueryModal, setShowPatronQueryModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const {events, getEvents, setQuery} = useEventsStore()

  // TABLE COLUMNS
  const contactCell = (row: PatronModel) => (
    <div className='d-flex flex-column'>
      <span className='fw-sembold mb-4 d-flex align-items-center'>
        {row.phone && row.phone.toString().length > 4 ? (
          <>
            {row.unsubscribedFromSMS && (
              <span className='ms-1 badge badge-light-warning'>unsubscribed</span>
            )}

            <a
              href={`tel:${row.phone}`}
              target='_blank'
              rel='noreferrer'
              className='text-dark text-hover-primary'
            >
              {formatPhoneNumber(row.phone.toString())}
            </a>
          </>
        ) : (
          <span className='text-muted '>no phone number</span>
        )}
      </span>

      <div className='fw-sembold d-flex align-items-center'>
        {row.email ? (
          <>
            <a
              href={`mailto:${row.email}`}
              target='_blank'
              rel='noreferrer'
              className='text-dark text-hover-primary'
            >
              {row.email}
            </a>
            {row.unsubscribedFromEmails === true && (
              <span className='ms-1 badge badge-light-warning'>unsubscribed</span>
            )}
          </>
        ) : (
          <span className='text-muted '>no email</span>
        )}
      </div>
    </div>
  )

  const nameCell = (row: PatronModel) => (
    <div className='d-flex align-items-center mb-1'>
      {/* symbol */}
      <div className='symbol symbol-50px me-3'>
        <span
          className={clsx('symbol-label text-white fw-bolder', {
            'bg-warning': row.unsubscribedFromEmails || row.unsubscribedFromSMS,
            'bg-secondary':
              !row.unsubscribedFromEmails && !row.isBanned && !row.unsubscribedFromSMS,
            'bg-danger': row.isBanned,
            'bg-success': !row.unsubscribedFromEmails && !row.isBanned && !row.unsubscribedFromSMS,
          })}
        >
          {row.name.charAt(0)}
        </span>
      </div>

      {/* name (and badges) */}
      <div className='d-flex flex-column '>
        <div className='d-flex align-items-center'>
          <span className='text-dark fw-bold text-hover-primary mb-1 fs-4 me-3'>{row.name}</span>

          {row.isBanned && <span className='badge badge-light-danger'>Banned</span>}
          {row.unsubscribedFromEmails && row.unsubscribedFromSMS && (
            <span className='badge badge-light-warning'>Unsubscribed</span>
          )}
        </div>

        {/* blurb */}
        <span className='text-muted fs-7'>
          {row.ticketsValue > 0 &&
            `${formatNumber(row.bookingsCount, 0, true)} ${pluralise(
              row.bookingsCount,
              'booking'
            )}. Spent ${formatMoney(
              row.ticketsValue,
              selectedAccountsUsers.account.currency,
              0,
              true
            )} on ${formatNumber(row.ticketsCount, 0, true)} ${pluralise(
              row.ticketsCount,
              'ticket'
            )}`}

          {row.ticketsValue === 0 &&
            row.ticketsCount > 0 &&
            `${formatNumber(row.bookingsCount, 0, true)} ${pluralise(
              row.bookingsCount,
              'booking'
            )}. Issued ${formatNumber(row.ticketsCount, 0, true)} ${pluralise(
              row.ticketsCount,
              'ticket'
            )}`}

          {row.ticketsValue === 0 && row.ticketsCount === 0 && 'no activity yet'}
        </span>
      </div>
    </div>
  )

  const tagsCell = (row: PatronModel) => (
    <div className='d-flex flex-grow-1 flex-wrap align-items center'>
      {row.tags &&
        row.tags.map((tag, index) => (
          <span key={`${tag}-${index}`} className='badge badge-secondary me-2 mb-2'>
            {tag}
          </span>
        ))}

      {row.tags && row.tags.length === 0 && <span className='text-muted'>no tags</span>}
    </div>
  )

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      cell: (row) => nameCell(row),
      sortable: true,
      width: '325px',
    },

    {
      name: 'Contact',
      cell: (row) => contactCell(row),
      sortable: true,
      maxwidth: '200px',
    },

    {
      name: 'Tags',
      selector: (row) => row.tags,
      sortable: false,
      maxwidth: '300px',
      cell: (row) => tagsCell(row),
    },

    {
      name: 'Action',
      cell: (row) => (
        <div className='d-flex justify-content-end'>
          <PatronEditor
            onChange={(patron) => handleUpdatePatron(patron)}
            availableTags={patronsTags}
            patron={row}
          />
        </div>
      ),
      width: '125px',
    },
  ]

  // RENDER // LOADING
  useEffect(() => {
    // this is triggered on page reload
    if (!selectedAccountsUsers.account.id) return

    if (currentPatronQuery.filters.length > 0) {
      setIsPatronQueryApplied(true)
      setPageLoading(false)
      return
    }

    if (searchTerm !== '') {
      setIsSearchApplied(true)
      setPageLoading(false)
      return
    }

    setPageLoading(true)
    const payload: PatronsPagination = {
      ...initialPatronPagination,
      account: selectedAccountsUsers.account.id,
      sortBy: 'name:asc',
    }

    getPatrons(payload)
      .catch((err) => {
        toast.error('Failed to load patrons', {duration: 5000})
      })
      .finally(() => setPageLoading(false))

    getPatronTags(selectedAccountsUsers.account.id)

    // get events
    setQuery({
      scope: 'all',
      limit: 9999999,
      sortBy: 'name:asc',
    })
    getEvents(selectedAccountsUsers.account.id)

    //   eslint-disable-next-line
  }, [selectedAccountsUsers.account.id])

  // SETUP BREADCRUMBS
  useEffect(() => {
    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Audience',
        title: 'All',
        link: '/audience',
      },
    ]

    if (currentPatronQuery) {
      crumbs.push({
        object: 'Audience',
        title: trimCharacters(currentPatronQuery.name, 12),
        link: '/audience',
      })
    }
    setBreadcrumbs(crumbs)
    setCurrentPage('Audience')

    // eslint-disable-next-line
  }, [currentPatronQuery])

  useEffect(() => {
    // set mode
    if (selectedRows.length > 0) {
      setMode('list')
    } else {
      if (isPatronQueryApplied) {
        setMode('patronQuery')
      } else if (isSearchApplied) {
        setMode('search')
      } else {
        setMode(undefined)
      }
    }

    // eslint-disable-next-line
  }, [selectedRows])

  const handleRowClick = (row: PatronModel) => {
    // if row is already selected, remove it
    if (selectedRows.some((selectedRow) => selectedRow.id === row.id)) {
      setSelectedRows(selectedRows.filter((selectedRow) => selectedRow.id !== row.id))
    } else {
      setSelectedRows([...selectedRows, row])
    }
  }

  const customStyles = {
    table: {
      style: {
        backgroundColor: 'transparent',
      },
    },

    headRow: {
      style: {
        border: 'none',
        backgroundColor: 'transparent',
        minHeight: '50px',
      },
    },

    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },

    rows: {
      style: {
        borderBottom: '1px solid #f5f5f5 !important',
        minHeight: '75px',
        borderRadius: '5px',
      },

      highlightOnHoverStyle: {
        backgroundColor: getCSSVariableValue('--bs-light'),
        borderRadius: '5px',
      },
      selectedHighlightStyle: {
        borderRadius: '5px',
        borderColor: `transparent`,
      },
    },

    pagination: {
      style: {
        border: 'none',
      },
    },
  }

  const handleUpdatePatron = (patron: PatronModel) => {
    updatePatrons(patron)
    // get tags
    getPatronTags(selectedAccountsUsers.account.id)
  }

  // PATRON QUERY
  const handleApplyPatronQuery = async (patronQueryId: string) => {
    setPageLoading(true)
    setIsSearchApplied(false)
    let payload: any = {
      ...initialPatronPagination,
      account: selectedAccountsUsers.account.id,
      patronQuery: patronQueryId,
    }
    payload = pick(payload, ['account', 'patronQuery', 'limit', 'page', 'sortBy'])

    getPatrons(payload)
      .catch((err) => {
        toast.error('Failed to apply query', {duration: 5000})
      })
      .then(() => {
        setIsPatronQueryApplied(true)
        setCurrentPatronQuery(patronQueryId)
        setMode('patronQuery')
        setSelectedRows([])
        setSearchTerm('')
      })
      .finally(() => setPageLoading(false))
  }

  const handleResetQuery = (resetSelectedRows = true) => {
    setPageLoading(true)

    const payload: PatronsPagination = {
      ...initialPatronPagination,
      account: selectedAccountsUsers.account.id,
    }

    getPatrons(payload)
      .then(() => {
        setIsPatronQueryApplied(false)
        unsetCurrentPatronQuery()
        setIsSearchApplied(false)
        setMode(undefined)
        setSearchTerm('')
        if (resetSelectedRows) setSelectedRows([])
      })
      .catch((err) => {
        toast.error('Failed to reset query', {duration: 5000})
      })
      .finally(() => setPageLoading(false))
  }

  const handlePaginationChange = (page: number) => {
    if (isSearchApplied) {
      handleSearch(page)
      return
    }

    setPageLoading(true)
    const payload: any = {
      ...pagination,
      account: selectedAccountsUsers.account.id,
      page,
    }

    if (isPatronQueryApplied) {
      payload.patronQuery = currentPatronQuery.id
    }

    getPatrons(payload)
      .catch((err) => {
        toast.error('Failed to load patrons', {duration: 5000})
      })
      .finally(() => setPageLoading(false))
  }

  // SEARCH
  const searchRef = React.useRef<HTMLInputElement>(null)
  const handleSearch = (page: number) => {
    if (searchRef.current || searchTerm) {
      const search = searchRef.current?.value || searchTerm
      setSearchTerm(search)

      if (!search || search === '') return

      // set is loading
      setPageLoading(true)

      // remove

      // search patrons
      const payload = {
        account: selectedAccountsUsers.account.id,
        search,
        page,
      }

      searchPatrons(payload)
        .then(() => {
          // reset
          setIsSearchApplied(true)
          setIsPatronQueryApplied(false)
          unsetCurrentPatronQuery()
          setMode('search')
          setSelectedRows([])
        })
        .catch((err) => toast.error('Failed to search patrons', {duration: 5000}))
        .finally(() => setPageLoading(false))
    }
  }

  // EXPORT
  const handleExport = async () => {
    // prepare payload
    const payload: ExportPatronArgs = {
      account: selectedAccountsUsers.account.id,
      mode: !mode ? 'all' : (mode as any),
    }

    // setup mode and accompanying data
    if (isPatronQueryApplied) {
      payload.patronQuery = currentPatronQuery.id
    } else if (isSearchApplied) {
      payload.search = searchTerm
    } else if (selectedRows.length > 0) {
      payload.list = selectedRows.map((p) => p.id!)
    }

    // save
    try {
      setExporting(true)
      const url = await exportPatrons(payload)
      window.open(url, '_blank', 'noopener,noreferrer')

      // detect safari
      if (/Apple/.test(navigator.userAgent)) {
        Swal.fire({
          icon: 'info',
          title: 'Exporting',
          text: 'Safari may open the file in a new tab instead of downloading it.  Please go to the new tab and click file > save or cmd + s if this happens.',
        })
      }
    } catch (err) {
      toast.error('Failed to export patrons')
    } finally {
      setExporting(false)
    }
  }

  // TAGS
  const handleSaveTags = async (tags: string[], operation) => {
    // check mode
    if (mode === undefined) return

    // prepare payload
    const payload: PatchPatronTagsArgs = {
      account: selectedAccountsUsers.account.id,
      tags,
      mode,
      operation,
    }

    // setup mode and accompanying data
    if (selectedRows.length > 0) {
      payload.list = selectedRows.map((p) => p.id!)
    } else if (isPatronQueryApplied) {
      payload.mode = 'patronQuery'
      payload.patronQuery = currentPatronQuery.id
    } else if (isSearchApplied) {
      payload.mode = 'search'
      payload.search = searchTerm
    } else {
      toast.error('No patrons selected')
      return
    }

    // save
    try {
      await patchPatronTags(payload)

      if (isPatronQueryApplied) {
        handleApplyPatronQuery(currentPatronQuery.id!)
      }
      if (isSearchApplied) {
        handleSearch(pagination.page)
      }
      if (!isPatronQueryApplied && !isSearchApplied) {
        handleResetQuery(false)
      }
    } catch (err) {
      toast.error('Failed to save tags')
    }
  }

  const handleImported = (importedData, importReport) => {
    toast.success(
      `Successfully imported: ${importReport.inserted} inserted, ${importReport.updated} updated, ${importReport.failed} ignored`,
      {duration: 5000}
    )

    // get updated patrons
    const payload = {
      ...initialPatronPagination,
      account: selectedAccountsUsers.account.id,
    }

    setPageLoading(true)

    getPatrons(payload)
      .catch((err) => {
        toast.error('Failed to load patrons', {duration: 5000})
      })
      .finally(() => setPageLoading(false))

    // get tags
    getPatronTags(selectedAccountsUsers.account.id)
  }

  return (
    <>
      {pageLoading && <PageLoadingSpinner />}
      <Toaster />
      <div className='row'>
        {/* LIST */}
        <div className='col-12'>
          {/* LIST HEADER */}
          <div className='row ms-md-0'>
            <div className='d-flex flex-column'>
              <div className='d-flex justify-content-between mb-10'>
                <div className='d-flex flex-column'>
                  {isPatronQueryApplied ? (
                    <span className='fs-5 fw-bold'>
                      {pagination.totalResults} {pluralise(patrons.length, 'Patron')} in '
                      {currentPatronQuery.name}'
                    </span>
                  ) : (
                    <span className='fs-5 fw-bold'>
                      {!isSearchApplied
                        ? `${pagination.totalResults} Patrons`
                        : `Search Results: ${searchTerm}`}
                    </span>
                  )}

                  <span className='d-flex text-muted fs-7'>
                    {/* pagination details */}
                    {!isSearchApplied
                      ? `viewing ${patrons.length} on pg ${pagination.page} of ${pagination.totalPages}`
                      : `viewing ${patrons.length}`}
                  </span>

                  {/* reset */}
                </div>
                <div className='d-flex flex-rows align-items-center'>
                  {/* RESET */}
                  {(isPatronQueryApplied || isSearchApplied) && (
                    <button
                      type='button'
                      onClick={() => handleResetQuery(true)}
                      className='btn btn-info btn-active-secondary me-2'
                    >
                      Reset
                    </button>
                  )}

                  {/* SEARCH BUTTON */}
                  <Tippy content='Search Patrons' placement='top'>
                    <button
                      className={clsx(
                        'btn btn-icon btn-outline btn-sm text-dark btn-active-secondary me-2',
                        {
                          'btn-secondary': mode === 'search',
                        }
                      )}
                      onClick={() => setShowSearchModal(true)}
                    >
                      <KTIcon
                        iconName='magnifier'
                        className={clsx('fs-3 text-dark p-0')}
                        iconType={mode === 'search' ? 'solid' : 'outline'}
                      />
                    </button>
                  </Tippy>

                  {/* CIRCLES BUTTON */}
                  <PatronQueryEditor
                    tags={patronsTags}
                    events={events}
                    isLoading={pageLoading}
                    onApplyQuery={handleApplyPatronQuery}
                    onResetQuery={handleResetQuery}
                    onOpened={() => setShowPatronQueryModal(false)}
                    displayMode={patronQueries.length > 0 ? 'list' : 'edit'}
                    buttonLabel={patronQueries.length > 0 ? '' : 'Add Circle'}
                    buttonIcon={'abstract-21'}
                    className={clsx('btn btn-active-secondary btn-icon btn-sm btn-outline me-2', {
                      'btn-icon': patronQueries.length > 0,
                      'btn-outline': patronQueries.length === 0,
                    })}
                  />

                  {/* TAGS */}
                  <PatronTags mode={mode} options={patronsTags} onSave={handleSaveTags} />

                  {/* IMPORTER */}
                  <PatronImporter mode='icon' onImported={handleImported} />

                  {/* EXPORTER */}
                  <Tippy content='Export Patrons' placement='top'>
                    <button
                      className='btn btn-icon btn-sm btn-outline btn-active-secondary'
                      onClick={handleExport}
                      disabled={patrons.length === 0}
                    >
                      {exporting ? (
                        <span className='spinner-border spinner-border-sm'></span>
                      ) : (
                        <KTIcon
                          iconName='cloud-download'
                          className='fs-2 text-dark p-0'
                          iconType='outline'
                        />
                      )}
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>
          </div>

          {/* LIST CONTENT */}
          {patrons && patrons.length > 0 && (
            <div className='row'>
              <div className='table-responsive'>
                <DataTable
                  keyField='id'
                  noTableHead
                  columns={columns}
                  customStyles={customStyles}
                  data={patrons}
                  onRowClicked={handleRowClick}
                  selectableRowsHighlight
                  highlightOnHover
                  pointerOnHover
                  selectableRowSelected={(row) =>
                    selectedRows.some((selectedRow) => selectedRow.id === row.id)
                  }
                />
              </div>
              {/* pagination */}
              {patrons.length < pagination.totalResults && (
                <div className='my-10'>
                  <PaginationControl
                    page={pagination.page}
                    total={pagination.totalResults}
                    limit={pagination.limit}
                    changePage={handlePaginationChange}
                  />
                </div>
              )}
            </div>
          )}

          {patrons.length === 0 && !pageLoading && (
            <div className='d-flex flex-column align-items-center justify-content-center w-100 mt-10 border border-dashed border-gray-300 bg-light rounded p-10'>
              <KTIcon iconName='inbox' className='fs-2x text-muted' />
              <span className='fs-5 text-muted'>
                No patrons found yet. But no problem, import a patron from a CSV file by clicking
                the button below
              </span>
              <PatronImporter
                buttonClassName={`btn btn-sm btn-info btn-active-secondary mt-5`}
                mode='button'
                onImported={handleImported}
              />
            </div>
          )}
        </div>
      </div>

      {/* SEARCH MODAL */}
      <Modal show={showSearchModal} centered onHide={() => setShowSearchModal(false)}>
        <Modal.Body>
          {/* FILTERS */}
          <div className='w-100 d-flex flex-column'>
            {/* SEARCH */}

            <div className='d-flex p-5 p-lg-7 w-100'>
              {/* search */}
              <div className='input-group'>
                <input
                  type='text'
                  ref={searchRef}
                  onKeyDown={(e) => e.key === 'Enter' && handleSearch(1)}
                  className='form-control border border-secondary form-control-solid'
                  placeholder='Search by name, email or phone'
                />
                <button
                  onClick={() => handleSearch(1)}
                  className='btn btn-outline btn-active-secondary'
                >
                  <KTIcon
                    iconName={isSearchApplied ? 'filter-search' : 'magnifier'}
                    iconType={isSearchApplied ? 'solid' : 'outline'}
                    className={clsx('fs-3 text-dark')}
                  />
                </button>
              </div>
              {isSearchApplied && (
                <Tippy content='Reset Search' placement='top'>
                  <button
                    onClick={() => handleResetQuery()}
                    className='btn btn-icon btn-active-secondary ms-2'
                  >
                    <KTIcon iconName='cross-circle' iconType='solid' className='fs-2 text-dark' />
                  </button>
                </Tippy>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* PATRON QUERY MODAL */}
      <Modal show={showPatronQueryModal} centered onHide={() => setShowPatronQueryModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter or Manage Circles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='w-100 d-flex flex-column'>
            <div className='d-flex justify-content-between mb-3 align-items-center'>
              <span className='fs-5 fw-bold'>
                <KTIcon iconName='abstract-21' iconType='outline' className='me-2 fs-3 text-dark' />{' '}
                Circles
              </span>
              <div className='d-flex align-items-center'>
                <PatronQueryEditor
                  tags={patronsTags}
                  events={events}
                  isLoading={pageLoading}
                  onApplyQuery={handleApplyPatronQuery}
                  onResetQuery={handleResetQuery}
                  onOpened={() => setShowPatronQueryModal(false)}
                  displayMode={'list'}
                  buttonLabel={patronQueries.length > 0 ? '' : 'Add Circle'}
                  buttonIcon={patronQueries.length > 0 ? 'setting-2' : 'plus-circle'}
                  className={clsx('btn btn-sm btn-active-secondary', {
                    'btn-icon': patronQueries.length > 0,
                    'btn-outline': patronQueries.length === 0,
                  })}
                />
                {isPatronQueryApplied && (
                  <Tippy content='Reset Filters' placement='top'>
                    <button
                      onClick={() => handleResetQuery()}
                      className='btn btn-sm btn-icon btn-active-secondary'
                    >
                      <KTIcon iconName='cross-circle' iconType='solid' className='fs-2 text-dark' />
                    </button>
                  </Tippy>
                )}
              </div>
            </div>

            {patronQueries.length > 0 && (
              <>
                <span className='fw-normal text-muted fs-7 mb-5'>
                  Filter by choosing the circles you want to view
                </span>{' '}
                <div className={'v-scrollable border border-light'} style={{maxHeight: '250px'}}>
                  {patronQueries.map((query) => (
                    <div
                      key={query.id}
                      onClick={() => {
                        // toggle
                        if (currentPatronQuery && currentPatronQuery.id === query.id) {
                          handleResetQuery()
                        } else {
                          handleApplyPatronQuery(query.id!)
                        }
                      }}
                      className={clsx(
                        'd-flex flex-column mb-2 rounded border border-secondary bg-hover-light p-3',
                        {
                          'bg-light': currentPatronQuery && currentPatronQuery.id === query.id,
                        }
                      )}
                    >
                      <div className='d-flex align-items-center'>
                        {currentPatronQuery && currentPatronQuery.id === query.id ? (
                          <KTIcon
                            iconName='check-circle'
                            iconType='solid'
                            className='text-dark fs-1 me-5'
                          />
                        ) : (
                          <div
                            className='w-20px h-20px me-5 border border-2 border-secondary'
                            style={{borderRadius: '50%'}}
                          ></div>
                        )}

                        <div className='d-flex flex-column'>
                          <div className='d-flex'>
                            <span className='fw-bold fs-5'>{query.name}</span>
                          </div>
                          <div className='text-muted fs-7 d-flex me-1'>
                            {query.filters.map((qp) => (
                              <QueryPartBadge
                                events={events}
                                key={qp.field}
                                queryPart={qp}
                                mode='text'
                                showConnector={
                                  query.filters.indexOf(qp) < query.filters.length - 1
                                    ? true
                                    : false
                                }
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {patronQueries.length === 0 && (
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <span className='text-muted fs-7'>No circles created yet</span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex align-items-center justify-content-end'>
            <button
              className='btn btn-sm btn-active-secondary me-2'
              onClick={() => setShowPatronQueryModal(false)}
            >
              Close
            </button>
            <button
              onClick={() => {
                handleResetQuery(true)
                setShowPatronQueryModal(false)
              }}
              className='btn btn-sm btn-outline btn-active-dark'
            >
              Reset Filters
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AudiencePage
