const placeholders = {
  generic: [
    'recipientName',
    'recipientEmail',
    'recipientPhone',
    'skybookingsPhoneLink',
    'skybookingsEmailLink',
  ],
  event: [
    'eventName',
    'eventUrl',
    'eventLink',
    'eventHandle',
    'eventStartDate',
    'eventEndDate',
    'patronLoginUrl',
    'patronLogin',
    'artworkUrl',
    'artworkImg',
    'logourl',
    'logoImg',
    'bannerUrl',
    'bannerImg',
    'location',
    'organiserName',
    'organiserEmail',
    'organiserPhone',
    'organiserWebsite',
    'organiserX',
    'organiserIG',
    'organiserFB',
    'daysUntilEvent',
    'daysAfterEvent',
    'eventBookingButton',
    'eventBookingLink',
  ],
  booking: ['bookingName', 'bookingDate', 'bookingStatus', 'bookingUrl', 'bookingLink'],
}

// data.bookingName = patron.name ? patron.name : 'Valued Patron';
//     data.bookingDate = moment(booking.startDate).format('D M YY');
//     data.bookingStatus = booking.status;
//     data.id = booking.id;
//     data.bookingUrl = `${config.urls.eventUrl}/${event.id}/booking/${booking.id}`;
//     data.bookingLink = `<a href='${data.bookingUrl}'>View Booking</a>`;

export default placeholders
